import React from 'react'
import { graphql } from 'gatsby'
// import get from 'lodash/get'
import { Helmet } from 'react-helmet'

// import ShopProduct from '../components/shopProduct'

const RootIndex = ({ data }) => {
  // const products = get(data, 'shopifyCollection.products', [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Shop | ${data.site.siteMetadata.title}`}</title>
        <meta property="og:title" content={`Shop | ${data.site.siteMetadata.title}`}></meta>
      </Helmet>
      
      <div className="shopWrapper">
        {/* {products && products.length && products.map((product, i) => {
          return (
            <ShopProduct
              product={product}
              key={i}
            />
          )
        })} */}
      </div>
    </React.Fragment>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

// export const pageQuery = graphql`
//   query IndexQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allShopifyCollection(filter: {handle: {eq: "webstore"}}) {
//       edges {
//         node {
//           title
//           handle
//           products {
//             id
//             shopifyId
//             title
//             handle
//             descriptionHtml
//             availableForSale
//             images {
//               originalSrc
//             }
//             priceRange {
//               minVariantPrice {
//                 amount
//               }
//               maxVariantPrice {
//                 amount
//               }
//             }
//             options {
//               name
//               values
//             }
//             variants {
//               shopifyId
//               title
//               availableForSale
//               price {
//                 amount
//                 currencyCode
//               }
//               selectedOptions {
//                 name
//                 value
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `